<template>
    <v-container fluid>
        <ApolloQuery
            :query="require('@/graphql/output-pool/allOutputPools.gql')"
            :variables="query_vars"
            deep
        >
            <template v-slot="{ result: { loading, error, data }, query }">
                <v-progress-linear indeterminate v-if="loading" />
                <v-data-iterator
                    v-if="data && !loading"
                    :items="applySearchFilter(data.allOutputPools)"
                    :search="keywords"
                    :items-per-page.sync="itemsPerPage"
                    :footer-props="{ itemsPerPageOptions }"
                >
                    <template v-slot:default="props">
                        <v-row>
                            <v-col
                                v-for="item in props.items"
                                :key="item.id"
                                cols="12"
                                sm="12"
                                md="6"
                                lg="6"
                            >
                                <output-pool-card
                                    :pool.sync="item"
                                    @delete-clicked="promptForDelete"
                                    @edit-clicked="editPool"
                                    @pool-updated="refreshQuery(query)"
                                    @open-version-dialog="
                                        $emit('open-version-dialog', item)
                                    "
                                />
                            </v-col>
                        </v-row>
                    </template>
                </v-data-iterator>
                <v-alert type="error" v-else-if="error">
                    There was an error loading data. The server said...<br />
                    <strong>{{ error }}</strong>
                </v-alert>
            </template>
        </ApolloQuery>
        <v-dialog
            v-model="deleteDialog"
            persistent
            max-width="320"
            v-if="selectedPool.name != ''"
        >
            <v-card>
                <v-card-title class="headline"
                    >Delete Output Pool?</v-card-title
                >
                <v-card-text>
                    <p>
                        You are about to delete the entire output pool
                        <strong>{{ selectedPool.name }}</strong
                        >. This will remove the output pool record as well as
                        any associations with deployment targets and projects.
                        This cannot be undone. Are you sure you want to delete
                        this output pool?
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="deleteDialog = false"
                        >{{ $t('cancel') }}</v-btn
                    >
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="deletePool(selectedPool)"
                        >{{ $t('delete') }}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import OutputPoolCard from '@/views/output-pool/OutputPoolCard'
import moment from 'moment'

export default {
    name: 'OutputPoolGrid',
    components: {
        'output-pool-card': OutputPoolCard,
    },
    props: {
        keywords: {
            type: String,
            required: true,
        },
        client_id: {
            type: String,
        },
        project_id: {
            type: String,
        },
        reload: {
            type: Number,
            default: 0,
        },
        outputFilterStartDate: {
            type: String,
        },
        outputFilterEndDate: {
            type: String,
        },
    },
    data: () => ({
        itemsPerPageOptions: [4, 8, 12],
        itemsPerPage: 8,
        deleteDialog: false,
        selectedPool: {},
        rando: 0,
    }),
    watch: {
        reload: function(val) {
            this.rando = val
        },
    },
    computed: {
        query_vars: function() {
            if (this.$store.getters.userForPortalAccess.user_type === 'admin') {
                return { garbage: this.rando }
            } else {
                return {
                    client_id: this.client_id,
                    project_id: this.project_id,
                    garbage: this.rando,
                }
            }
        },
    },
    methods: {
        promptForDelete(pool) {
            this.selectedPool = pool
            this.deleteDialog = true
        },
        deletePool(pool) {
            this.$apollo
                .mutate({
                    // Query
                    mutation: require('@/graphql/output-pool/deleteOutputPool.gql'),
                    // Parameters
                    variables: {
                        id: pool.id,
                    },
                })
                .then(data => {
                    // report success
                    this.$emit('pool-form-closed', data)
                    this.rando++
                })
                .catch(error => {
                    // Error
                    console.error(error)
                    // update user....
                })
            this.deleteDialog = false
        },
        editPool(pool) {
            this.$emit('edit-pool', pool)
        },
        refreshQuery(query) {
            query.refetch()
        },
        formatOutputDateToTimeNum(date) {
            return moment(date, 'YYYY-MM-DD HH:mm:ss').valueOf()
        },
        formatInputDateToTimeNum(date) {
            return moment(date, 'YYYY-MM-DD').valueOf()
        },
        filterClientId(item) {
            if (this.client_id && item.client) {
                return this.client_id === item.client.id
            }
            return true
        },
        filterStartDate(item) {
            if (this.outputFilterStartDate && item.created_at) {
                return (
                    this.formatOutputDateToTimeNum(item.created_at) >=
                    this.formatInputDateToTimeNum(this.outputFilterStartDate)
                )
            }
            return true
        },
        filterEndDate(item) {
            if (this.outputFilterEndDate && item.updated_at) {
                return (
                    this.formatOutputDateToTimeNum(item.updated_at) <=
                    this.formatInputDateToTimeNum(this.outputFilterEndDate)
                )
            }
            return true
        },
        applySearchFilter(items) {
            if (items) {
                return items
                    .filter(this.filterClientId)
                    .filter(this.filterStartDate)
                    .filter(this.filterEndDate)
            }
            return items
        },
    },
}
</script>
