var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form"},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Output Pool Form")])]),_c('v-card-text',[(_vm.formLoading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('name'),"disabled":_vm.formLoading,"required":"","rules":[_vm.rules.required],"validate-on-blur":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('version'),"disabled":_vm.formLoading,"required":"","rules":[_vm.rules.required],"validate-on-blur":""},model:{value:(_vm.formData.version),callback:function ($$v) {_vm.$set(_vm.formData, "version", $$v)},expression:"formData.version"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":`${_vm.$t('index_url')} (optional)`,"placeholder":"Enter the relative path for the manual index page here.  ie /index.htm","disabled":_vm.formLoading},model:{value:(_vm.formData.index_url),callback:function ($$v) {_vm.$set(_vm.formData, "index_url", $$v)},expression:"formData.index_url"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ApolloQuery',{attrs:{"query":require('@/graphql/client/clientsDropdown.gql')},scopedSlots:_vm._u([{key:"default",fn:function({
                                result: { loading, error, data },
                            }){return [(loading)?_c('v-autocomplete',{attrs:{"label":"Loading..."}}):(data)?_c('v-autocomplete',{attrs:{"items":data.allClients,"disabled":_vm.formLoading,"loading":loading,"item-text":"name","item-value":"id","label":"Client","required":"","rules":[_vm.rules.required],"validate-on-blur":""},model:{value:(_vm.client_id),callback:function ($$v) {_vm.client_id=$$v},expression:"client_id"}}):_vm._e()]}}])})],1),(_vm.client_id > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('ApolloQuery',{attrs:{"query":require('@/graphql/project/projectsDropdown.gql'),"variables":{
                                client_id: this.formData.client.id,
                            }},scopedSlots:_vm._u([{key:"default",fn:function({
                                result: { loading, error, data },
                            }){return [(loading)?_c('v-autocomplete',{attrs:{"label":"Loading..."}}):(data)?_c('v-autocomplete',{attrs:{"items":data.allProjects,"disabled":_vm.formLoading,"loading":loading,"item-text":"name","no-data-text":"No Projects Available For Selected Client","item-value":"id","label":"Project","required":"","rules":[_vm.rules.required],"validate-on-blur":""},model:{value:(_vm.project_id),callback:function ($$v) {_vm.project_id=$$v},expression:"project_id"}}):_vm._e()]}}],null,false,3884041970)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"name":"description","label":"Description","hint":"Enter description here...","rules":[_vm.rules.required],"validate-on-blur":""},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"name":"notes","label":"Notes","rules":[_vm.rules.required],"hint":"Enter notes here..."},model:{value:(_vm.formData.notes),callback:function ($$v) {_vm.$set(_vm.formData, "notes", $$v)},expression:"formData.notes"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":"","disabled":_vm.formLoading},on:{"click":_vm.closeModal}},[_vm._v(_vm._s(_vm.$t('close')))]),(_vm.formData.id)?_c('v-btn',{attrs:{"color":"primary darken-1","text":"","disabled":_vm.formLoading},on:{"click":function($event){return _vm.updatePool()}}},[_vm._v(_vm._s(_vm.$t('save')))]):_vm._e(),(!_vm.formData.id)?_c('v-btn',{attrs:{"color":"primary darken-1","text":"","disabled":_vm.formLoading},on:{"click":function($event){return _vm.createPool()}}},[_vm._v(_vm._s(_vm.$t('create')))]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }