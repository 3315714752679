<template>
    <v-form ref="form">
        <v-card>
            <v-card-title>
                <span class="headline">Output Pool Form</span>
            </v-card-title>
            <v-card-text>
                <v-progress-linear v-if="formLoading" indeterminate />
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="formData.name" :label="$t('name')" :disabled="formLoading" required
                                :rules="[rules.required]" validate-on-blur />
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field v-model="formData.version" :label="$t('version')" :disabled="formLoading"
                                required :rules="[rules.required]" validate-on-blur />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="formData.index_url" :label="`${$t('index_url')} (optional)`"
                                placeholder="Enter the relative path for the manual index page here.  ie /index.htm"
                                :disabled="formLoading" />
                        </v-col>
                        <v-col cols="12">
                            <ApolloQuery :query="require('@/graphql/client/clientsDropdown.gql')
                                ">
                                <template v-slot="{
                                    result: { loading, error, data },
                                }">
                                    <v-autocomplete v-if="loading" label="Loading..." />
                                    <v-autocomplete v-else-if="data" :items="data.allClients" :disabled="formLoading"
                                        :loading="loading" item-text="name" item-value="id" v-model="client_id"
                                        label="Client" required :rules="[rules.required]" validate-on-blur />
                                </template>
                            </ApolloQuery>
                        </v-col>

                        <v-col cols="12" v-if="client_id > 0">
                            <ApolloQuery :query="require('@/graphql/project/projectsDropdown.gql')
                                " :variables="{
                                    client_id: this.formData.client.id,
                                }">
                                <template v-slot="{
                                    result: { loading, error, data },
                                }">
                                    <v-autocomplete v-if="loading" label="Loading..." />
                                    <v-autocomplete v-else-if="data" :items="data.allProjects" :disabled="formLoading"
                                        :loading="loading" item-text="name"
                                        no-data-text="No Projects Available For Selected Client" item-value="id"
                                        v-model="project_id" label="Project" required :rules="[rules.required]"
                                        validate-on-blur />
                                </template>
                            </ApolloQuery>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea name="description" label="Description" v-model="formData.description"
                                hint="Enter description here..." :rules="[rules.required]" validate-on-blur />
                        </v-col>

                        <v-col cols="12">
                            <v-textarea name="notes" label="Notes" v-model="formData.notes" :rules="[rules.required]"
                                hint="Enter notes here..." />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="closeModal" :disabled="formLoading">{{ $t('close') }}</v-btn>
                <v-btn v-if="formData.id" color="primary darken-1" text :disabled="formLoading" @click="updatePool()">{{
                    $t('save')
                    }}</v-btn>
                <v-btn v-if="!formData.id" color="primary darken-1" text :disabled="formLoading"
                    @click="createPool()">{{
                    $t('create') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>
<script>
export default {
    name: 'OutputPoolForm',
    props: {
        pool: {
            type: Object,
            default: () => ({
                id: '',
                name: '',
                description: '',
                index_url: '',
                version: '',
                notes: '',
                client: {},
                project: {},
            }),
        },
    },
    data: () => ({
        isValid: true,
        mutation: '',
        formLoading: false,
        defaultForm: {
            id: '',
            name: '',
            description: '',
            index_url: '',
            notes: '',
            version: '',
            client: {},
            project: {},
        },
        formData: {},
        rules: {
            required: value => !!value || 'This field is required',
            arrayRequired: value =>
                (Array.isArray(value) && value.length > 0) ||
                'At least one value is required',
        },
    }),
    created() {
        this.formData = { ...this.defaultForm, ...this.pool }
    },
    computed: {
        client_id: {
            // this feels wrong, but it works...
            // getter
            get: function () {
                if (typeof this.pool.client !== 'undefined') {
                    if (typeof this.pool.client.id !== 'undefined') {
                        return this.pool.client.id
                    } else {
                        return ''
                    }
                } else {
                    if (this.formData.client.id > 0) {
                        return this.formData.client.id
                    } else {
                        return ''
                    }
                }
            },
            // setter
            set: function (newValue) {
                this.formData.client = { id: newValue }
            },
        },
        project_id: {
            // this feels wrong, but it works...
            // getter
            get: function () {
                if (typeof this.pool.project !== 'undefined') {
                    if (typeof this.pool.project.id !== 'undefined') {
                        return this.pool.project.id
                    } else {
                        return ''
                    }
                } else {
                    return ''
                }
            },
            // setter
            set: function (newValue) {
                this.formData.project = { id: newValue }
            },
        },
    },
    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.isValid = true
                this.saveForm()
            }
        },
        reset() {
            this.$refs.form.reset()
        },
        resetValidation() {
            this.$refs.form.resetValidation()
        },
        updatePool() {
            this.mutation = require('@/graphql/output-pool/updateOutputPool.gql')
            this.saveForm()
        },
        createPool() {
            this.mutation = require('@/graphql/output-pool/createOutputPool.gql')
            this.saveForm()
        },
        closeModal() {
            this.resetValidation()
            this.$emit('pool-form-closed')
        },
        saveForm() {
            if (!this.$refs.form.validate()) {
                return
            }
            this.formLoading = true
            this.$apollo
                .mutate({
                    // Query
                    mutation: this.mutation,
                    // Parameters
                    variables: {
                        id: this.formData.id,
                        name: this.formData.name,
                        version: this.formData.version,
                        index_url: this.formData.index_url,
                        description: this.formData.description,
                        notes: this.formData.notes,
                        client: this.formData.client.id,
                        project: this.formData.project.id,
                    },
                })
                .then(data => {
                    // report success
                    this.$emit('pool-form-saved')
                    this.$emit('pool-form-closed', data)
                    this.$store.dispatch('responseMessage', {
                        type: 'success',
                        title: 'Success',
                        text: 'Version saved',
                    })
                })
                .catch(error => {
                    // Error
                    this.$store.dispatch('responseMessage', {
                        type: 'error',
                        title: 'Error',
                        text: error,
                    })
                    // update user....
                })
            this.formLoading = false
        },
    },
    watch: {
        pool: {
            handler: function () {
                this.formData = { ...this.defaultForm, ...this.pool }
            },
            deep: true,
        },
    },
}
</script>
