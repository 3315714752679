<template>
    <v-card>
        <v-card-title>
            <h3>Deployment Output Targets</h3>
            <v-spacer />
            <v-text-field
                v-model="keywords"
                append-icon="mdi-magnify"
                label="Search Output Targets..."
                clearable
                single-line
                hide-details
            />
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        v-on="on"
                        color="primary"
                        @click="newOutputTargetForm()"
                        fab
                        dark
                    >
                        <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <span>New Output Targets</span>
            </v-tooltip>
        </v-card-title>
        <v-card-text>
            <v-tabs background-color="primary" dark left>
                <v-tab left><v-icon>mdi-earth</v-icon>Web</v-tab>
                <v-tab><v-icon>mdi-file-pdf-box</v-icon>PDF</v-tab>
                <v-tab>All Targets</v-tab>
                <v-tab-item>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <output-target-grid
                                    :keywords.sync="keywords"
                                    build_type="web"
                                    :output_pool_id.sync="output_pool_id"
                                    :reload.sync="reloadGrid"
                                    @edit-target="editOutputTarget"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <output-target-grid
                                    build_type="pdf"
                                    :keywords.sync="keywords"
                                    :output_pool_id.sync="output_pool_id"
                                    :reload.sync="reloadGrid"
                                    @edit-target="editOutputTarget"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <output-target-grid
                                    build_type="all"
                                    :keywords.sync="keywords"
                                    :output_pool_id.sync="output_pool_id"
                                    :reload.sync="reloadGrid"
                                    @edit-target="editOutputTarget"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
            </v-tabs>
        </v-card-text>
        <v-dialog v-model="outputTargetFormDialog" persistent max-width="600">
            <output-target-form
                :target.sync="formOutputTarget"
                @target-form-saved="
                    closeOutputTargetForm()
                    reloadTheGrid()
                "
                @target-form-closed="closeOutputTargetForm()"
            />
        </v-dialog>
    </v-card>
</template>
<script>
import OutputTargetGrid from '@/views/output-target/OutputTargetGrid'
import OutputTargetForm from '@/views/output-target/OutputTargetForm'

export default {
    name: 'home',
    components: {
        'output-target-grid': OutputTargetGrid,
        'output-target-form': OutputTargetForm,
    },
    props: ['output_pool_id'],
    data: () => ({
        keywords: '',
        outputTargetFormDialog: false,
        formOutputTarget: {},
        reloadGrid: 0,
        defaultForm: {
            id: '',
            name: '',
            img: '',
            sort: '',
            version: '',
            target_url: '',
            client: {},
            project: {},
            output_pool: {},
            is_favorite: false,
        },
    }),

    methods: {
        newOutputTargetForm: function() {
            this.formOutputTarget = this.defaultForm
            this.outputTargetFormDialog = true
        },
        closeOutputTargetForm: function() {
            this.outputTargetFormDialog = false
        },
        editOutputTarget: function(target) {
            this.formOutputTarget = target
            this.outputTargetFormDialog = true
        },
        reloadTheGrid: function() {
            this.reloadGrid++
        },
    },
}
</script>
