<template>
    <v-card>
        <v-toolbar dark color="primary">
            <v-btn icon dark @click="$emit('close-version-dialog')">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ pool.name }} Versions</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="$emit('close-version-dialog')"
                    >Close</v-btn
                >
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-list two-line>
                <v-list-item v-for="version in pool.versions" :key="version.id">
                    <v-list-item-action>
                        <div class="text-center">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-on="on"
                                        class="mx-2"
                                        :dark="version.is_current === 'y'"
                                        fab
                                        small
                                        @click="makeCurrentVersion(version)"
                                        :loading="requestLoading"
                                    >
                                        <v-icon
                                            :dark="version.is_current === 'y'"
                                            >mdi-checkbox-marked-circle</v-icon
                                        >
                                    </v-btn>
                                </template>
                                <span v-if="version.is_current === 'n'"
                                    >Make Current Version</span
                                >
                                <span v-else>Current Version</span>
                            </v-tooltip>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="mx-2"
                                        v-on="on"
                                        :dark="version.is_testing === 'y'"
                                        fab
                                        small
                                        :loading="requestLoading"
                                        @click="makeTestingVersion(version)"
                                    >
                                        <v-icon
                                            :dark="version.is_testing === 'y'"
                                            >mdi-test-tube</v-icon
                                        >
                                    </v-btn>
                                </template>
                                <span v-if="version.is_testing === 'n'"
                                    >Make Testing Version</span
                                >
                                <span v-else>Testing Version</span>
                            </v-tooltip>

                            <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-on="on"
                                        class="mx-2"
                                        fab
                                        small
                                        :loading="requestLoading"
                                        @click="deleteVersion(version)"
                                    >
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete Version</span>
                            </v-tooltip>
                        </div>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-icon fab left>
                                {{ typeIcon(version.build_type) }}
                            </v-icon>
                            {{ version.build_type.toUpperCase() }} Build
                            {{ version.version }}</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                            formatDate(version.created_at)
                        }}</v-list-item-subtitle>
                        {{ version.notes }}
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'VersionDialog',
    props: {
        pool: {
            type: Object,
            default: () => ({
                name: '',
                id: '',
                versions: [],
            }),
        },
    },
    data: () => ({
        requestLoading: false,
    }),
    methods: {
        deleteVersion: function(version) {
            this.requestLoading = true
            this.$apollo
                .mutate({
                    // Query
                    mutation: require('@/graphql/output-pool-versions/deleteOutputPoolVersion.gql'),
                    // Parameters
                    variables: {
                        id: version.id,
                    },
                })
                .then(data => {
                    // report success
                    this.$emit('version-updated', data)
                    this.$emit('close-version-dialog')
                    this.$store.dispatch('responseMessage', {
                        type: 'success',
                        title: 'Success',
                        text: 'Version saved',
                    })
                    this.requestLoading = false
                })
                .catch(error => {
                    // Error
                    this.$store.dispatch('responseMessage', {
                        type: 'error',
                        title: 'Error',
                        text: error,
                    })
                    this.requestLoading = false
                    // update user....
                })
        },
        makeCurrentVersion: function(version) {
            this.requestLoading = true
            this.$apollo
                .mutate({
                    // Query
                    mutation: require('@/graphql/output-pool-versions/updateOutputPoolVersion.gql'),
                    // Parameters
                    variables: {
                        id: version.id,
                        is_current: 'y',
                        build_type: version.build_type,
                    },
                })
                .then(data => {
                    // report success
                    this.$emit('version-updated', data)
                    this.$emit('close-version-dialog')
                    this.$store.dispatch('responseMessage', {
                        type: 'success',
                        title: 'Success',
                        text: 'Version saved',
                    })
                    this.requestLoading = false
                })
                .catch(error => {
                    // Error
                    this.$store.dispatch('responseMessage', {
                        type: 'error',
                        title: 'Error',
                        text: error,
                    })
                    this.requestLoading = false
                    // update user....
                })
        },
        makeTestingVersion: function(version) {
            this.requestLoading = true
            this.$apollo
                .mutate({
                    // Query
                    mutation: require('@/graphql/output-pool-versions/updateOutputPoolVersion.gql'),
                    // Parameters
                    variables: {
                        id: version.id,
                        is_testing: 'y',
                        build_type: version.build_type,
                    },
                })
                .then(data => {
                    // report success
                    this.$emit('version-updated', data)
                    this.$emit('close-version-dialog')
                    this.$store.dispatch('responseMessage', {
                        type: 'success',
                        title: 'Success',
                        text: 'Version saved',
                    })
                    this.requestLoading = false
                })
                .catch(error => {
                    // Error
                    this.$store.dispatch('responseMessage', {
                        type: 'error',
                        title: 'Error',
                        text: error,
                    })
                    this.requestLoading = false
                })
        },
        typeIcon: function(build_type) {
            if (build_type === 'web') {
                return 'mdi-earth'
            } else {
                return 'mdi-file-pdf-box'
            }
        },
        formatDate: function(date) {
            return new Date(date).toLocaleDateString('en-us', {
                hour: 'numeric',
                minute: 'numeric',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour12: true,
            })
        },
    },
}
</script>

<style scoped></style>
