<template>
    <v-card>
        <v-list-item>
            <v-list-item-avatar color="grey">
                <v-icon dark>mdi-clipboard-list</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                {{ pool.name }}
                <v-list-item-subtitle>
                    {{ pool.client.name }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-card-text>
            <p>
                Version: {{ pool.version }}
                <br />
                <span v-if="currentWebVersion === 'None'"
                    >Current Web Version:
                    <span style="color:red;font-weight:bold">None</span><br
                /></span>
                <span v-else
                    >Current Web Version: {{ currentWebVersion }}<br
                /></span>
                <span v-if="currentPdfVersion === 'None'"
                    >Current Pdf Version:
                    <span style="color:red;font-weight:bold">None</span><br
                /></span>
                <span v-else
                    >Current Pdf Version: {{ currentPdfVersion }}<br
                /></span>

                Uploaded Versions: {{ pool.versions.length }}
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            icon
                            v-on="on"
                            @click="$emit('open-version-dialog')"
                            ><v-icon>mdi-binoculars</v-icon></v-btn
                        >
                    </template>
                    <span>View/Manage Versions List</span>
                </v-tooltip>
                <br />
                Index Path: <br />
                {{ pool.index_url }}<br />
                Public Description: <br />
                {{ pool.description }}<br /><br />
                Internal Notes: <br />
                {{ pool.notes }}<br />
            </p>
            <v-divider class="my-5" />
            <v-icon left>mdi-earth</v-icon
            ><strong>Web Files SFTP Build Path:</strong> (click url to copy to
            clipboard)
            <br />
            <v-btn
                class="text-none"
                small
                text
                v-clipboard="sftpWebUrl"
                @click="copySuccess(sftpWebUrl)"
            >
                <v-icon left>mdi-content-copy</v-icon>
                {{ sftpWebUrl }}</v-btn
            >
            <br />

            <v-btn
                v-if="pool.has_pending_web_files"
                class="text-none"
                color="error"
                text
                small
                @click="deployPendingWebBuild"
            >
                <v-icon color="error" left>mdi-folder-sync</v-icon>
                Pending Web Build Detected. Click to deploy...</v-btn
            >
            <v-btn color="success" text small class="text-none" v-else
                ><v-icon left>mdi-check</v-icon> No pending Web Builds</v-btn
            >
            <v-divider class="my-5" />
            <v-icon left>mdi-file-pdf-box</v-icon
            ><strong>PDF Files SFTP Build Path:</strong> (click url to copy to
            clipboard)
            <br />
            <v-btn
                class="text-none"
                small
                text
                v-clipboard="sftpPdfUrl"
                @click="copySuccess(sftpPdfUrl)"
            >
                <v-icon left>mdi-content-copy</v-icon>
                {{ sftpPdfUrl }}</v-btn
            >
            <br />

            <v-btn
                v-if="pool.has_pending_pdf_files"
                class="text-none"
                small
                color="error"
                outlined
                text
                @click="deployPendingPdfBuild"
            >
                <v-icon color="error" left>mdi-folder-sync</v-icon>
                Pending PDF Build Detected. Click to deploy...</v-btn
            >
            <v-btn color="success" text small class="text-none" v-else
                ><v-icon left>mdi-check</v-icon> No pending Pdf Builds</v-btn
            >
            <v-divider class="mt-5" />
        </v-card-text>
        <v-card-actions>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="viewPoolTargets(pool)">
                        <v-icon>mdi-bullseye</v-icon>
                    </v-btn>
                </template>
                <span>Deployment Targets</span>
            </v-tooltip>

            <v-spacer />

            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="editPool">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>Edit</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="promptForDelete">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
                <span>Delete {{ pool.name }}</span>
            </v-tooltip>
        </v-card-actions>
        <v-dialog v-model="deployDialog" persistent max-width="400">
            <deploy-files-dialog
                :pool="pool"
                :build_type="build_type"
                @deploy-files-closed="closeDeployForm"
                @deploy-files-saved="$emit('pool-updated')"
            />
        </v-dialog>
    </v-card>
</template>

<script>
import DeployFilesDialog from '@/views/output-pool/DeployFilesDialog'

export default {
    name: 'OutputPoolCard',
    components: {
        'deploy-files-dialog': DeployFilesDialog,
    },
    props: {
        pool: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        build_type: '',
        deployDialog: false,
    }),
    computed: {
        docCount: function() {
            return 5
        },
        currentWebVersion: function() {
            const current = this.pool.versions.filter(
                e => e.is_current && e.build_type === 'web'
            )
            if (current.length < 1) {
                return 'None'
            } else {
                return (
                    current[0].version +
                    ' uploaded ' +
                    this.formatDate(current[0].created_at)
                )
            }
        },
        currentPdfVersion: function() {
            const current = this.pool.versions.filter(
                e => e.is_current && e.build_type === 'pdf'
            )
            if (current.length < 1) {
                return 'None'
            } else {
                return (
                    current[0].version +
                    ' uploaded ' +
                    this.formatDate(current[0].created_at)
                )
            }
        },
        userCount: function() {
            return 0
            //return this.pool.users.length || 0;
        },
        sftpWebUrl: function() {
            return (
                '/home/forge/' +
                process.env.VUE_APP_API_UPLOAD_URL +
                '/storage/app/builds/pending/' +
                this.pool.id +
                '/web'
            )
        },
        sftpPdfUrl: function() {
            return (
                '/home/forge/' +
                process.env.VUE_APP_API_UPLOAD_URL +
                '/storage/app/builds/pending/' +
                this.pool.id +
                '/pdf'
            )
        },
    },
    methods: {
        viewPoolTargets(pool) {
            this.$router.push({ path: `/targets/${pool.id}` })
        },
        loadForm(pool) {
            this.$emit('loadPoolForm', pool)
        },
        formatDate: function(date) {
            return new Date(date).toLocaleDateString('en-us', {
                hour: 'numeric',
                minute: 'numeric',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour12: true,
            })
        },
        promptForDelete() {
            this.$emit('delete-clicked', this.pool)
        },
        editPool() {
            this.$emit('edit-clicked', this.pool)
        },
        copySuccess(msg) {
            this.$store.dispatch('responseMessage', {
                type: 'success',
                title: 'Success',
                text: msg + ' ' + this.$t('copied_to_clipboard'),
            })
        },
        closeDeployForm() {
            this.deployDialog = false
        },
        deployPendingWebBuild() {
            this.build_type = 'web'
            this.deployDialog = true
        },
        deployPendingPdfBuild() {
            this.build_type = 'pdf'
            this.deployDialog = true
        },
    },
}
</script>
